import { FilterType } from '../../../../lib/api/models/hessel-api/hire';
import { ShopFacetGroup } from '../../../../lib/api/models/shop/product-list';
import { FacetGroup } from '../../../../lib/view-models/vehicle';

export const getFilterRenderType = (group: FacetGroup | ShopFacetGroup): FilterType => {
    return getFilterRenderTypeByGroupKey(group.key);
};

export const getFilterRenderTypeByGroupKey = (key: string): FilterType => {
    switch (key) {
        case 'parentColors.name.keyword':
            return 'Color';
        case 'mainColors':
            return 'Main Colors';
        case 'hireInformation.priceFrom':
        case 'purchaseTypes.hire.baseMonthlyPrice':
        case 'purchaseTypes.engros.basePrice':
        case 'purchaseTypes.cash.basePrice':
        case 'purchaseTypes.financing.baseMonthlyPrice':
        case 'purchaseTypes.privateLeasing.baseMonthlyPrice':
        case 'operationalOrFinancialLeasingPrice':
        case 'specification.maximumTowingWeight':
        case 'specification.mixedDriving':
        case 'specification.range':
        case 'specification.horsePowerForFilter':
        case 'specification.firstYearOfRegistration':
        case 'specification.odometerReadingInKm':
        case 'purchaseTypes.taxation.basePrice':
        case 'specification.totalWeight':
        case 'specification.payload':
        case 'price':
            return 'Dropdown interval';
        case 'locationId':
            return 'Department';
        case 'paymentTypes.value.keyword':
            return 'Toggle';
        case 'brand.keyword':
            return 'Checkbox with read more';
        case 'operationalOrFinancialLeasingDownPayment':
            return 'Maximum value';
        case 'fits':
            return 'Your car';
        default:
            return 'Checkbox';
    }
};

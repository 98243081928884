import { APIResponse } from '..';
import { VehicleApiVersion } from '../../../constants/api-const';
import { AvailabilityFilterKey } from '../../../constants/site-consts';
import { getAPI } from '../helpers';
import { hesselApiTypes } from '../models/hessel-api';

const Vehicle_BASE_API_URL = `api/${VehicleApiVersion}`;

type ApiRequestType = {
    filter: string[];
    from: number;
    take: number;
    basefilter?: string[];
    sort?: string;
};

export const getProductList = async (
    request: hesselApiTypes.ProductListRequest,
    vehicleType: string
): APIResponse<hesselApiTypes.ProductListApiResponse> => {
    const baseFilterQuery = request.filters.find((x) => x.startsWith(AvailabilityFilterKey));
    const remainingFilters = request.filters.filter((x) => !x.startsWith(AvailabilityFilterKey));
    let requestObject: ApiRequestType = {
        filter: remainingFilters,
        from: request.from,
        take: request.take,
    };
    if (baseFilterQuery) {
        requestObject = {
            ...requestObject,
            basefilter: [baseFilterQuery, `category__${vehicleType}`],
        };
    }
    if (request.sort) {
        requestObject = {
            ...requestObject,
            sort: request.sort,
        };
    }
    return getAPI<hesselApiTypes.ProductListApiResponse>(`${Vehicle_BASE_API_URL}/vehicles/filter/${request.configurationId}`, requestObject);
};

export const getParentColorAssets = async (): APIResponse<hesselApiTypes.ParentColor[]> => {
    return getAPI(`${Vehicle_BASE_API_URL}/vehicles/parent-colours`);
};

import { VehicleApiVersion } from '../../../constants/api-const';
import { getAPI } from '../helpers';
import { BundleApiResponse } from '../models/hessel-api';
import { APIResponse } from '../types';

const Vehicle_BASE_API_URL = `api/${VehicleApiVersion}`;

export const getAllBundles = async (): APIResponse<BundleApiResponse> => {
    return getAPI<BundleApiResponse>(`${Vehicle_BASE_API_URL}/vehicles/bundles`);
};
export const getBundlesByVariant = async (vehicleId: string): APIResponse<BundleApiResponse> => {
    return getAPI<BundleApiResponse>(`${Vehicle_BASE_API_URL}/vehicles/bundle/${vehicleId}`);
};

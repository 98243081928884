import { getAPI, postAPI } from '../helpers';
import { SalesVideoData, SubmitActivityParams, SubmitRatingParams } from '../models/hessel-api/sales-video';
import { APIResponse } from '../types';

export const getSalesVideoDetails = async (params: string): APIResponse<SalesVideoData> => {
    return getAPI<SalesVideoData>(`api/salesVideo/v1/${params}`);
};

export const submitActivity = async (params: SubmitActivityParams): Promise<void> => {
    await postAPI(`api/salesVideo/v1/${params.videoDetailId}/activities`, params);
};

export const submitRating = async (params: SubmitRatingParams): Promise<void> => {
    await postAPI(`api/salesVideo/v1/${params.videoDetailId}/ratings`, params);
};
